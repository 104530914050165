import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import {JsonView, allExpanded, defaultStyles} from 'react-json-view-lite';
import {alertService} from '../services';
import {availableModes} from '.';
import 'react-json-view-lite/dist/index.css';

export function copyDeep(object) {
    return JSON.parse(JSON.stringify(object));
}

export function copyToClipboard(value, onSuccess = null) {
    navigator.clipboard
        .writeText(value)
        .then(() => {
            alertService.success(`<strong>${value}</strong> copied to clipboard.`);
            if (onSuccess !== null) {
                onSuccess();
            }
        })
        .catch(() => {
            alertService.error('Something went wrong with copying data to clipboard');
        });
}

export function createMarkerPopup(marker, setUserSelection) {
    const coordinates = marker.getLatLng();
    const coordinatesString = `${coordinates.lat},${coordinates.lng}`;

    const elementUl = document.createElement('ul');
    elementUl.setAttribute('class', 'list-unstyled');

    // search nearby
    const elementLiSearch = document.createElement('li');
    const elementLiSearchLink = document.createElement('a');
    elementLiSearchLink.setAttribute('href', '');
    elementLiSearchLink.textContent = 'Search around here';
    elementLiSearchLink.onclick = e => {
        e.preventDefault();
        setUserSelection({
            'data': {
                'latitude': coordinates.lat,
                'longitude': coordinates.lng,
            },
            'id': coordinatesString,
            'display_name': coordinatesString,
            'type': 'Coordinates',
        });
        marker.closePopup();
    };
    elementLiSearch.appendChild(elementLiSearchLink);
    elementUl.appendChild(elementLiSearch);

    // launch Google Maps
    const elementLiGmaps = document.createElement('li');
    const elementLiGmapsLink = document.createElement('a');
    elementLiGmapsLink.setAttribute('href', `https://www.google.com/maps/search/?api=1&query=${coordinatesString}`);
    elementLiGmapsLink.setAttribute('target', '_blank');
    elementLiGmapsLink.textContent = 'Open in Google Maps';
    elementLiGmaps.appendChild(elementLiGmapsLink);
    elementUl.appendChild(elementLiGmaps);

    return elementUl;
}

export function createMetadataPopup(title, data) {
    return L.popup({
        'maxHeight': 250,
        'maxWidth': 300,
    })
        .setContent(getMetadataPopup(title, data));
}

export function filter(formValues, data) {
    let filteredData = data;
    Object.keys(formValues).forEach(localFormValueKey => {
        const formValue = formValues[localFormValueKey];
        // identify the right column, either a first or a second level property key
        const isNextLevel = formValue.keyNextLevel !== '';
        filteredData = filteredData.filter(data => {
            // hit the right data based on the selected level
            if (isNextLevel === true) {
                // this is made for data arrays like "connectionPoints"
                const connectionPoints = JSON.parse(data.properties[formValue.key]);
                const matchedConnectionPoints = connectionPoints.filter(connectionPoint => {
                    return filterCheck(formValue.condition, connectionPoint[formValue.keyNextLevel], formValue.value);
                });

                return matchedConnectionPoints.length > 0;
            } else {
                return filterCheck(formValue.condition, data.properties[formValue.key], formValue.value);
            }
        });
    });

    return filteredData;
}

export function filterCheck(condition, data, value) {
    if (null === data) {
        return false;
    }

    // e.g. codesPostaux is a one-element array
    if (true === Array.isArray(data)) {
        data = data.pop();
    }

    // e.g. substations on high voltage lines
    if ('object' === typeof data && true === 'code' in data) {
        data = data.code;
    }

    switch (condition) {
        default:
        case 'eq':
            return data == value; // it is important to not type-compare since int, float, string can be compared
        case 'gte':
            return data >= value;
        case 'lte':
            return data <= value;
        case 'incl':
            if ('string' === typeof data) {
                return true === data.toLowerCase().includes(value.toLowerCase());
            }
            if (true === Array.isArray(data)) {
                return true === data.includes(value);
            }
    }
}

export function getAvailableModes(country) {
    const countryAvailableModes = {};
    for (const [key, value] of Object.entries(availableModes)) {
        if (country in value.activeLayers) {
            countryAvailableModes[key] = value;
        }
    }

    return countryAvailableModes;
}

// returns the average latitude and longitude for an array of coordinates
export function getCenter(coordinates) {
    const [y, x] = coordinates.reduce((sum, coord) => {
        sum[0] += parseFloat(coord[0]);
        sum[1] += parseFloat(coord[1]);
        return sum;
    }, [0, 0]);

    return [y / coordinates.length, x / coordinates.length];
}

// get array of coordinates from geometry, the one and only for polygons or the one with the most elements for MultiPolygon
export function getCoordinatesForGeometry(geometry) {
    if (geometry.type === 'MultiPolygon') {
        let maxElementsCount = -1;
        let maxElementsKey;
        for (let i = 0; i < geometry.coordinates.length; i++) {
            const elementsCount = geometry.coordinates[i]?.[0].length;
            if (maxElementsCount < elementsCount) {
                maxElementsCount = elementsCount;
                maxElementsKey = i;
            }
        }
        return geometry.coordinates[maxElementsKey]?.[0];
    }

    return geometry.coordinates[0];
}

export function getDynamicStyles(fieldMapping, dynamicStyles, key, properties) {
    const dynamicKey = fieldMapping !== undefined && key in fieldMapping ? fieldMapping[key] : null;
    const value = properties !== undefined && dynamicKey in properties ? properties[dynamicKey] : null;
    if (
        value !== null && // we have a value that requires mapping
        dynamicStyles !== null && // we have a style definition
        key in dynamicStyles && // we have a style definition for our key
        value in dynamicStyles[key] // we have a style for our value
    ) {
        return dynamicStyles[key][value];
    }

    return null;
}

export function getDynamicStylesWithCondition(dynamicStyles, value) {
    // get value based on condition
    const condition = 'condition' in dynamicStyles ? dynamicStyles['condition'] : null;
    let values = 'values' in dynamicStyles ? dynamicStyles['values'] : {};

    // order values based on condition to check them in the right order
    let valuesKeys = Object.keys(values).map(value => parseFloat(value)).sort();
    if (condition === 'gte') {
        valuesKeys.reverse(); // highest value needs to be checked first for >=
    }

    // loop through given values from dynamicStyles and attempt to match the condition
    for (const valuesKey of valuesKeys) {
        const returnValue = values[valuesKey];
        switch (condition) {
            case 'eq':
                if (value == valuesKey) {
                    return returnValue;
                }
                break;
            case 'gte':
                if (value >= valuesKey) {
                    // console.log('we have a match', valuesKey, values[valuesKey]);
                    return returnValue;
                }
                break;
            case 'lte':
                if (value <= valuesKey) {
                    return returnValue;
                }
                break;
            case 'incl':
                if (value.includes(valuesKey) === true) {
                    return returnValue;
                }
                break;
        }
    }

    return null;
}

export function getGeoJsonFromElements(elements) {
    const geoJson = [];
    elements.forEach(element => {
        if (undefined !== element) {
            geoJson.push({
                'geometry': element.geometry,
                'id': element._id,
                'properties': {
                    'display_name': element.display_name, // @TODO: API should send this in metadata
                    ...element.metadata,
                },
                'type': 'Feature',
            });
        }
    });

    return geoJson;
}

export function getLatLng(string, separator = ',') {
    let coordinates = string.split(separator).map(coordinate => {
        return parseFloat(coordinate.trim());
    });

    // didn't find comma-separated coordinates, try semicolon
    if (coordinates.length !== 2 || coordinates.includes(NaN)) {
        if (separator !== ';') {
            return getLatLng(string, ';');
        }

        return null;
    }

    return coordinates;
}

export function getMetadataPopup(title, data) {
    return renderToString(
        <div>
            <h5>{title}</h5>
            <pre>{jsonPrettify(data)}</pre>
        </div>
    );
}

// get dynamic style for feature
export function getStyle(layer, feature) {
    let color = null;
    let fillColor = null;
    let fillOpacity = null;
    let weight = null;

    // @TODO: understand why layer.style is undefined every now and then

    // if a layer has default styles, move away from legacy and attempt to style dynamically
    if (undefined !== layer.style.default) {
        color = layer.style.default.color;
        fillColor = layer.style.default.fillColor;
        fillOpacity = layer.style.default.fillOpacity;
        weight = layer.style.default.weight;
    }

    switch (layer.type) {
        case 'plot':
            // only deal with layers that come with a customization
            if (undefined !== layer.style.dynamic && 'rating' in layer.style.dynamic) {
                // we need this custom code since we have ratings on a substation-level, get the highest substation rating
                const substations = 'substations' in feature.properties ? feature.properties['substations'] : [];
                let rating = 0;


                // get the biggest rating of the provided substations
                substations.forEach(substation => {
                    if ('NUM_rating' in substation && substation['NUM_rating'] > rating) {
                        rating = substation['NUM_rating'];
                    }
                });

                const dynamicStyles = getDynamicStylesWithCondition(layer.style.dynamic['rating'], rating);
                if (dynamicStyles !== null) {
                    color = dynamicStyles.color || color;
                    fillColor = dynamicStyles.fillColor || fillColor;
                    fillOpacity = dynamicStyles.fillOpacity || fillOpacity;
                    weight = dynamicStyles.weight || weight;
                }
            }

            break;

        case 'highVoltageLine':
            if (undefined !== layer.style.dynamic) {
                const dynamicStylesCapacity = getDynamicStylesWithCondition(layer.style.dynamic['capaciteInjectionDispo'], feature.properties.capaciteInjectionDispo);

                if (dynamicStylesCapacity !== null) {
                    weight = dynamicStylesCapacity.weight || weight;
                }

                const dynamicStylesType = getDynamicStyles({'type': 'type'}, layer.style.dynamic, 'type', feature.properties);
                if (dynamicStylesType !== null) {
                    color = dynamicStylesType.color || color;
                    fillColor = dynamicStylesType.fillColor || fillColor;
                    fillOpacity = dynamicStylesType.fillOpacity || fillOpacity;
                }
            }

            break;

        case 'projectPlot':
            // layer has no default colors, use legacy fallback
            if (color === null) {
                color = fillColor = feature.properties['Validation.Status'] === 'KO' ? '#FF2F00' : '#4E9B47';
            } else {
                const dynamicStyles = getDynamicStyles(layer.fieldMapping, layer.style.dynamic, 'validationStatus', feature.properties);
                if (dynamicStyles !== null) {
                    color = dynamicStyles.color || color;
                    fillColor = dynamicStyles.fillColor || fillColor;
                    fillOpacity = dynamicStyles.fillOpacity || fillOpacity;
                    weight = dynamicStyles.weight || weight;
                }
            }

            break;

        case 'road':
            const importance = "nombre_de_voies" in feature.properties ? feature.properties.nombre_de_voies : null;

            switch (importance) {
                case 4:
                case 3:
                    color = "#BBD18B";
                    weight = 4;
                    break;
                case 2:
                    color = "#FFF5D1";
                    weight = 2;
                    break;
                default:
                case 1:
                    color = "#FF97AF";
                    weight = 1;
                    break;
            }

            break;

        case 'substation':
            // layer has no default colors, use legacy fallback
            if (color === null) {
                color = fillColor = feature.properties['status'] === 'OK' ? '#4EFF00' : '#F52789';
            } else {
                const dynamicStyles = getDynamicStyles(layer.fieldMapping, layer.style.dynamic, 'status', feature.properties);
                if (dynamicStyles !== null) {
                    color = dynamicStyles.color || color;
                    fillColor = dynamicStyles.fillColor || fillColor;
                }
            }

            return {
                ...layer.style.default,
                'color': color,
                'fillColor': fillColor,
                'radius': 5,
            };

        case 'urbanism':
            const featureType = 'typezone' in feature.properties ? feature.properties.typezone.toUpperCase() : null;
            switch (featureType) {
                default:
                case 'A':
                case 'AH':
                    color = '#FFFF4D';
                    break;
                case 'N':
                case 'NH':
                    color = '#82B83C';
                    break;
                case 'AUC':
                case 'AUS':
                case 'U':
                    color = '#E96560';
                    break;
            }
            break;

        default:
            break;
    }

    return {
        ...layer.style.default,
        'color': color,
        'fillColor': fillColor,
        'fillOpacity': fillOpacity,
        'weight': weight,
    };
}

export function inverseCoordinates(coordinates) {
    return coordinates.map(coordinate => [coordinate[1], coordinate[0]]);
}

export function isObjectEmpty(object) {
    return 0 === Object.keys(object).length;
}

export function jsonPrettify(json) {
    return <JsonView data={json} shouldExpandNode={allExpanded} style={defaultStyles} />;
}

export function numberPrettify(number) {
    return new Intl.NumberFormat('fr-FR').format(number);
}

export function resetFeatureStyle(layerRef) {
    // we don't always have a reference, e.g. for react native's municipality polygons
    if (null !== layerRef) {
        // reset styles of all features
        layerRef.resetStyle();
    }
}
