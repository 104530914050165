import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import L from 'leaflet';
import {useMap} from 'react-leaflet/hooks';
import {FilterLine} from '.';
import {setSelection as setUserSelection, setSubstation as setUserSubstation} from '../redux/userSlice';
import {operatorService} from '../services';
import {createMetadataPopup, getStyle} from '../utils';

export function GeneralDataLayers({filtersShow, searchIsLoading}) {
    const dispatch = useDispatch();
    const map = useMap();
    const layerGroup = L.layerGroup();

    const country = useSelector(state => state.country.value.current);
    const userLayers = useSelector(state => state.user.value.layers);
    const layers = useSelector(state => state.layers.value.general);

    const [dataLayers, setDataLayers] = useState([]);

    // render layers, either when layers change or when userLayers change
    useEffect(() => {
        loadDataLayers();

        return () => {
            reset();
            setDataLayers([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers, userLayers]);

    const loadDataLayers = () => {
        reset();
        layerGroup.addTo(map);

        if (null !== layers && null !== userLayers) {
            const localDataLayers = [];
            layers.filter(localLayer => country in userLayers && Object.keys(userLayers[country]).includes(localLayer._id) === true).map(localLayer => {
                operatorService.getElements(localLayer._id)
                    .then(items => {
                        if (items.length > 0) {
                            const newLayer = {
                                ...localLayer,
                                'data': [],
                                'defaults': {
                                    'condition': '',
                                    'key': '',
                                    'keyNextLevel': '',
                                    'value': '',
                                },
                                'isFilterOpen': false,
                            };
                            items.forEach(item => {
                                newLayer.data.push({
                                    '_id': item._id,
                                    'display_name': item.display_name,
                                    'geometry': item.geometry,
                                    'properties': item.metadata,
                                    'type': 'Feature',
                                });
                            });

                            localDataLayers.push({
                                'layer': newLayer,
                                'layerRef': renderLayer(newLayer),
                            });

                            setDataLayers([...localDataLayers]);
                        }
                    });
            });
        }
    };

    const onFeatureHover = (feature, event) => {
        const popup = createMetadataPopup('Metadata', feature.properties);
        popup
            .setLatLng(event.latlng)
            .openOn(map);
    };

    const onFeatureSelect = (layer, feature) => {
        if (layer.type === 'substation') {
            const substation = {
                '_id': layer._id,
                'code': feature.properties.code,
                'display_name': feature.display_name,
                'geometry': feature.geometry,
                'metadata': feature.properties,
                'type': 'Substation',
            };
            dispatch(setUserSelection(substation));
            dispatch(setUserSubstation(substation));
        }
    };

    const renderLayer = layer => {
        return L.geoJSON(layer.data, {
            'onEachFeature': (feature, localLayer) => {
                localLayer.on({
                    'click': () => onFeatureSelect(layer, feature),
                    'mouseover': event => onFeatureHover(feature, event),
                    'mouseout': () => {
                        map.closePopup();
                    },
                });
            },
            // style points
            'pointToLayer': (feature, leaflet) => {
                return L.circleMarker(leaflet, getStyle(layer, feature));
            },
            // style polygons
            'style': feature => {
                return getStyle(layer, feature);
            },
        }).addTo(layerGroup);
    };

    const reset = () => {
        // remove each layer reference, without updateDataLayer won't have a clean map
        dataLayers.forEach(dataLayer => {
            dataLayer.layerRef.remove();
        });
        // clear layers from layer group
        layerGroup.clearLayers();
        // remove entire layer group from map
        map.removeLayer(layerGroup);
    };

    const updateDataLayer = updatedDataLayer => {
        reset();
        layerGroup.addTo(map);

        setDataLayers(dataLayers.map(localDataLayer => {
            if (updatedDataLayer.layer._id === localDataLayer.layer._id) {
                return {
                    'layer': updatedDataLayer.layer,
                    'layerRef': renderLayer(updatedDataLayer.layer),
                };
            }

            return localDataLayer;
        }));
    };

    if (filtersShow === true && searchIsLoading === false && dataLayers.length > 0) {
        return (
            <div className="filter stripes">
                <ul className="list-group list-group-flush">
                    {dataLayers.sort((a, b) => a.layer.name > b.layer.name ? 1 : -1).map((dataLayer, key) =>
                        <FilterLine
                            key={key}
                            layer={dataLayer.layer}
                            layerRef={dataLayer.layerRef}
                            resetDataLayer={loadDataLayers}
                            updateDataLayer={updateDataLayer}
                        />)}
                </ul>
            </div>
        );
    }
}
